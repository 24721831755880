import React from 'react'
import {
    SimpleGrid,
    Image,
    Flex,
    Heading, Box, Container,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    IoAnalyticsSharp,
    IoFolderOpen,
    IoHardwareChipSharp, IoPieChart, IoTerminal
} from 'react-icons/io5';
import image from './../assets/progressive_app.svg'

const Feature = ({ text, icon, iconBg }) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

function Services() {
    return (
        <>
            <Box p={10}>
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading>Our <Text as={'span'} color={'yellow.400'}>Services</Text></Heading>
                    <Text>
                        We make customer service attainable by implementing business processes that are simple and efficient as possible for our customers.
                    </Text>
                </Stack>
            </Box>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} py={12}>
                <Stack
                    spacing={4}
                    divider={
                        <StackDivider
                            borderColor={useColorModeValue('gray.100', 'gray.700')}
                        />
                    }>
                    <Feature
                        icon={
                            <Icon as={IoHardwareChipSharp} color={'yellow.500'} w={5} h={5} />
                        }
                        iconBg={useColorModeValue('yellow.100', 'yellow.900')}
                        text={'Software Solutions'}
                    />
                    <Feature
                        icon={<Icon as={IoTerminal} color={'green.500'} w={5} h={5} />}
                        iconBg={useColorModeValue('green.100', 'green.900')}
                        text={'IT Equipments'}
                    />
                    <Feature
                        icon={
                            <Icon as={IoPieChart} color={'purple.500'} w={5} h={5} />
                        }
                        iconBg={useColorModeValue('purple.100', 'purple.900')}
                        text={'Managed IT Services'}
                    />
                    <Feature
                        icon={<Icon as={IoFolderOpen} color={'pink.500'} w={5} h={5} />}
                        iconBg={useColorModeValue('pink.100', 'pink.900')}
                        text={'Training'}
                    />
                    <Feature
                        icon={
                            <Icon as={IoAnalyticsSharp} color={'red.500'} w={5} h={5} />
                        }
                        iconBg={useColorModeValue('red.100', 'red.900')}
                        text={'Business Development'}
                    />
                </Stack>
                <Flex>
                    <Image
                        rounded={'md'}
                        alt={'our services'}
                        src={image}
                    />
                </Flex>
            </SimpleGrid>
        </>
    )
}

export default Services