import React from 'react'
import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber, Image, Spacer, Heading, Divider, Text, Stack,Container,
    useColorModeValue,
    Center,
} from '@chakra-ui/react';

import rain_logo from './../assets/rain_logo.svg'
import aws_logo from './../assets/aws_logo.svg'
import huwawe_logo from './../assets/Huawei_Standard_logo.png'


function StatsCard(props) {
    const { title, stat, icon } = props;
    return (
        <Stat
            px={{ base: 2, md: 4 }}
            py={'5'}
            shadow={'xl'}
            border={'1px solid'}
            borderColor={useColorModeValue('gray.800', 'gray.500')}
            rounded={'lg'}>
            <Flex justifyContent={'space-between'}>
                <Box pl={{ base: 2, md: 4 }}>
                    <StatLabel fontWeight={'medium'} isTruncated>
                        {title}
                    </StatLabel>
                    <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                        {stat}
                    </StatNumber>
                </Box>
                <Box
                    my={'auto'}
                    color={useColorModeValue('gray.800', 'gray.200')}
                    alignContent={'center'}>
                    {icon}
                </Box>
            </Flex>
        </Stat>
    );
}

function Sponsors() {
    return (
        <>
            <Divider mt={12} mb={12} />
            <Box>
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading>Our <Text as={'span'} color={'yellow.400'}>Partners and Clients</Text></Heading>
                </Stack>
            </Box>
            <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
                <Flex justify="center" direction={{ base: "column", sm: "row", md: "row" }}>
                    {/* Rain */}
                    <Box
                        role={'group'}
                        p={4}
                        m={4}
                        bg={"whiteAlpha.900"}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        pos={'relative'}
                    >
                        <Center>
                            <Image
                                alt="Rain Network"
                                rounded={'lg'}
                                height={"100px"}
                                width={"100px"}
                                objectFit={'scale-down'}
                                src={rain_logo}
                            />
                        </Center>
                    </Box>
                    {/* AWS */}
                    <Box
                        role={'group'}
                        p={4}
                        m={4}
                        bg={"whiteAlpha.900"}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        pos={'relative'}
                    >
                        <Center>
                            <Image
                                alt="Amazon web services"
                                rounded={'lg'}
                                height={"100px"}
                                width={"100px"}
                                objectFit={'scale-down'}
                                src={aws_logo}
                            />
                        </Center>
                    </Box>
                    {/* Huwawei */}
                    <Box
                        role={'group'}
                        p={4}
                        m={4}
                        bg={"whiteAlpha.900"}
                        boxShadow={'2xl'}
                        rounded={'lg'}
                        pos={'relative'}
                    >
                        <Center>
                            <Image
                                alt="Huwawei mobile services"
                                rounded={'lg'}
                                height={"100px"}
                                width={"100px"}
                                objectFit={'scale-down'}
                                src={huwawe_logo}
                            />
                        </Center>
                    </Box>
                </Flex>


                {/* </SimpleGrid> */}
            </Box>
        </>
    )
}

export default Sponsors