import React from 'react'
import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack,
    Button,
    Flex,
    Divider,
    chakra, Image,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import image from './../assets/team_collaboration.svg'

function About() {

    const features = [{

        id: 1,
        title: 'What we believe in...',
        text: "Our culture is centred aroundfinding efficient ways toconstantly solve problems usingeffective and lean methods.",

    },
    {

        id: 2,
        title: 'Our values',
        text: "Innovation, frugality, andexcellent customer service arethe three most important pillarswhich we base our work ethicon.",

    }]

    return (
        <>
            <Box p={4}>
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading>Our <Text as={'span'} color={'yellow.400'}>Culture</Text></Heading>
                </Stack>
            </Box>

            <Container maxW={'6xl'} mt={10}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
                    {features.map((feature) => (
                        <HStack key={feature.id} align={'top'}>
                            <VStack align={'start'}>
                                <Text color={'yellow.400'} fontWeight={600}>{feature.title}</Text>
                                <Text>{feature.text}</Text>
                            </VStack>
                        </HStack>
                    ))}
                </SimpleGrid>
            </Container>

            <Divider mt={12} mb={12} />

            {/*  Our proposition section */}
            <Box p={4}>
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading>Our Value <Text as={'span'} color={'yellow.400'}>Proposition</Text></Heading>
                </Stack>
            </Box>

            <Container maxW={'6xl'} mt={10}>

                <Box as={Container} maxW="7xl" mt={14} p={4}>
                    <Grid
                        templateColumns={{
                            base: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        gap={4}>
                        <GridItem colSpan={1}>
                            <VStack alignItems="flex-start" spacing="20px">
                                <Image
                                    // layout='fill'
                                    rounded={'md'}
                                    alt={'feature image'}
                                    src={image}
                                // objectFit={'cover'}
                                />
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <Flex>
                                <chakra.p>
                                    We aim to make customerattainable by implementingbusiness process which areeffecient to make our client&apos;s lives easier.
                                </chakra.p>
                            </Flex>
                            <Divider m={2} />

                            <Flex>
                                <chakra.p>
                                    We partner with reputablevendors and business partnersto bring good products andservices closer to your reach.
                                </chakra.p>
                            </Flex>
                        </GridItem>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default About