import React from 'react'
import {IconButton} from "@chakra-ui/button";
import {useColorMode} from "@chakra-ui/color-mode";
import {Flex, VStack, Heading, Spacer} from "@chakra-ui/layout";
import {
    FaSun,
    FaMoon,
    FaInstagram,
    FaFacebook,
    FaLinkedin
} from 'react-icons/fa'

function Navbar() {

    // const { colorMode, toggleColorMode } = useColorMode();
    // const isDark = colorMode === "dark";

    return (<>
        <Flex w="100%">
            <Heading ml="8" size="md" fontWeight='semibold' color="yellow.300">P2P Networks</Heading>
            <Spacer></Spacer>
            <IconButton icon={<FaLinkedin/>}
                isRound='true'></IconButton>
            <IconButton ml={2}
                icon={<FaInstagram/>}
                isRound='true'></IconButton>
            <IconButton mr={8}
                icon={<FaFacebook/>}
                isRound='true'></IconButton>
        </Flex>
    </>)
}

export default Navbar
