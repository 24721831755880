import { VStack } from "@chakra-ui/react"
import Herosection from "./components/Herosection";
import Services from "./components/Services";
import Layout from './layout/layout';
import About from './components/About';
import Sponsors from "./components/Sponsors";

function App() {

  return (
    <Layout>
      <Herosection />
      <Services />
      <About />
      <Sponsors/>
    </Layout>


  )
}

export default App
