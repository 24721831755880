import React from 'react'
import { Stack, Heading, Text, Button, Box } from '@chakra-ui/react';

function Herosection() {
    return (
        <Stack
            as={Box}
            textAlign={'center'}
            spacing={{ base: 8, md: 14 }}
            py={{ base: 25, md: 30 }}>
            <Heading
                fontWeight={600}
                fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
                lineHeight={'110%'}>
                Let&apos;s get you connected <br />
                <Text as={'span'} fontStyle="" color={'yellow.400'}>
                    on P2P Networks
                </Text>
            </Heading>
            <Text>
                We arean IT solutions company that provides technical support, IT equipment reselling, consulting, cloud solutions, training and business development.
            </Text>
            <Stack
                direction={'column'}
                spacing={3}
                align={'center'}
                alignSelf={'center'}
                position={'relative'}>
                <Button
                    colorScheme={'yellow'}
                    bg={'yellow.400'}
                    rounded={'full'}
                    px={6}
                    _hover={{
                        bg: 'yellow.500',
                    }}>
                    Get Started
                </Button>
            </Stack>
        </Stack>
    )
}

export default Herosection